@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
@import 'variables';
@import 'typography';
@import 'mixins';
@import 'buttons';

.faqs {
  background-color: $grey-100;
  padding: 0 0 1rem;

  .grid {
    background-color: $white;
    border-radius: 1.5rem;
    margin: 0 0.5rem;
    padding: 4rem 1rem;
    max-width: 1320px;

    @include screen-size('medium') {
      margin: 0 1rem;
      padding: 4rem 11.25rem;
    }

    @include screen-size('custom', 1352px) {
      margin: 0 auto;
    }

    > div {
      flex-grow: 1;
    }
  }
}
